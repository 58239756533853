export const fields = [
    { key: 'full_name', label: "Nama", _style:'min-width:100px' },
    { key: 'position', label: "Position", _style:'min-width:100px' },
    { key: 'code', label: "code", _style:'min-width:100px' },
    { key: 'username', label: "Username", _style:'min-width:100px' },
    { key: 'email', label: "E-Mail", _style:'min-width:100px' },
    { key: "role", label: "Role", _style:'min-width:50px'},
    { key: "pd", label: "Perangkat Daerah", _style:'min-width:50px'},
    { key: "verificator_name", label: "Verfikator", _style:'min-width:50px'},
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'}
]
